import React,{useState,useEffect,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { APIURL,TZ } from "../../Global";
import EditMedicine from './EditMedicine'
//import {MedContext} from "../contexts/MedContext";
import "./currentmedications.css";
import DatePopUp from './DatePopUp';
//import ImageScale from '../ImageScale/ImageScale';
import ViewRemark from './ViewRemark';
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { useAlert } from "react-alert";
import AddRemark from '../ServiceRequests/AddRemark';
function CurrentMedications(props){
  const alert = useAlert();
  const [drugInt, setdrugInt] = useState(false);
  const [infoShow, setinfoShow] = useState(false);
const [img ,setImg] = useState([]);
const [index ,setIndex]= useState();
//const [medRender,setMedRender]=useContext(MedContext);
const [dateShow ,setDateShow] =useState(false)
const [prescData ,setPrescData]= useState([]);
const [url,setUrl]=useState("")

const [medList,setMedList]=useState([    { drug: "",
                 
  from: "",
  to:"",
  is_emergency: false,
  is_paused:false,
  dosage: "",

  intake_count: [],
  intake_time: '',
  remarks: ""
   }]);
const [action,setAction]=useState("")
  const [editShow, setEditShow]= useState(false)      
  const [render,]=useState(true);
  const [errorMsg,setErrorMsg]=useState('');
  const [doctor1,setDoctorname]=useState('');
  const [submitMsg,setSubmitMsg]=useState("");
  const [modalShow,setModalShow] =useState(false);
  const [imageShow, setImageShow]= useState(false)
  //const [clickShow ,setClickShow]= useState(false)
  const [remarkData, setRemarkData]= useState('')
  const [viewShow, setViewShow]= useState(false)
const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);
const [deleteMedConfirmMsg,setDeleteMedConfirmMsg]=useState("");
const [deleteMedConfirmModalShow,setDeleteMedConfirmModalShow]=useState(false);
const [refresh, setRefresh]=useState(false);
const [medIndexToDelete,setMedIndexToDelete] = useState();
const [medDelete, setMedDelete] = useState(false);
const [medRefresh, setMedRefresh] = useState(false);
const[dataList,setDataList]=useState([])
const [infoData1, setInfoData1] = useState(""); 
const id = props.id;
 const refrsh = props.refresh;

 const [keys,] = useState({'z' :false });


           useEffect(()=>{
            const handleKeyDown =(e)=>
            {      
              keys[e.key]=true
          
              if(e.altKey && keys['z']){
                setErrorSubmitModalShow(false)
                setErrorMsg('')
               
               // setMedRender(!medRender)
                setSubmitMsg('')
              }
          
            }
            window.addEventListener('keydown', handleKeyDown)
            
            
            const handleKeyUp =(ev)=>
          {
              
              keys[ev.key]= false
            }
          
          window.addEventListener('keyup', handleKeyUp)
          
          
          
            return()=>{
              window.removeEventListener('keydown', handleKeyDown)
              window.removeEventListener('keyup', handleKeyUp)
              
            }
          },[submitMsg, errorMsg]);


 
  useEffect(()=>{

    setMedList(medList)
   
  },[render, medList]);
  

  useEffect(()=>{

  

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
   const currentDate = new Date();
   const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
   
 // Get current timezone dynamically
 const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   
    
   
    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=current_medicines&patient_id=${id}&timeZone=${currentTimezone}&current_date=${formattedDate}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){

        let doctorname="";

        let data = []
        if(res.data.doctor.length ===0 && res.data.patient.length ===0){
          data=[]
        }

        if(res.data.doctor.length !==0){
          res.data.doctor.map((item)=> {
            data = data.concat(item.medicines)
            // doctorname=data.doctor_name;
          })
        }
        if(res.data.patient.length !==0){
          res.data.patient.map((item)=> {
            data = data.concat(item.medicine)
            //patient_name=data.patient_name;
          })
        }


        // if(res.data.prescription.length !==0){
        //   res.data.prescription.map((item)=> {
        //     data = data.concat(item.medicines)
        //   })
        // }
        setMedList(data)
       
        //setDoctorname(doctorname)
       
      //  if (res.data && res.data.doctor && res.data.doctor.length > 0) {
      //     const data = res.data.doctor.medicines;
      //     setMedList(data);
      


      }else{

        setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
        setErrorSubmitModalShow(true);
       // setMedList([]);
      }


    })
    .catch(err=>{
  
  setErrorMsg("Error in fetching data");
  setErrorSubmitModalShow(true);
})
 

 
},[id, refresh, refrsh])


/*const AddRow=()=>{
  const list = medList;
  list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
  
  setMedList(list);
  setRender(!render);
  
}*/





const modalClose = () => {
  setEditShow(false)    
  setRefresh(!refresh);
};


const EditPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size='lg'
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Body className="text-center">
        <EditMedicine data={dataList} modalClose={modalClose}/>
      </Modal.Body>
    </Modal>
  );
}

const InfoPopup1 = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      
      <Modal.Header closeButton>
      <span  >{infoData1.drug.charAt(0).toUpperCase() + (infoData1.drug).slice(1).toLowerCase()}</span>
     
      </Modal.Header>
      <Modal.Body>
      {drugInt?
        <div style={{textAlign:"left", border: "2px solid #e4dd9f5c",}}>
        <div style={{paddingLeft:"2%" }} className="row ">
           <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Drug interactions</div></div>
           <div style={{  border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.med_interactions}</div></div>
              </div>
              <div style={{ paddingLeft:"2%"}} className="row">
           <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>URL</div></div>
           <div style={{ border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.med_url}</div></div>
              </div>
                    
        </div>
        :
        <div>
        <div style={{ marginBottom: "1%" }} className="row">
           <div style={{ backgroundColor: "rgb(13, 197, 197)", color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-5"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Dosage form</div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Intake time </div></div>
              <div style={{ backgroundColor: "rgb(13, 197, 197)",color:"white", border: "2px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>Route</div></div>
              </div>
            <div style={{ marginBottom: "1%" }} className="row">
             
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-5"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>{(infoData1.form).charAt(0).toUpperCase() + (infoData1.form).slice(1).toLowerCase().replace(/_/g, ' ')}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.intake_time}</div></div>
              <div style={{ backgroundColor: "#e4dd9f", border: "2px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "6%", paddingBottom: "2%", textAlign: "center", }}>{infoData1.route&&infoData1.route.replace(/_/g, ' ').replace(/-/g, ' ')}</div></div>

            </div>

        {/* <ReactTable 
         columns={columns1}
         data={infoData1}
         defaultPageSize = {1}
         showPagination={false}
         style={{textAlign:"center"}}
         className="observation-table"
         >

         </ReactTable>   */}
                    
        </div>}
      </Modal.Body>
    </Modal>
  );
};
const handleShow=(index)=> {
  const newarr=medList;
  const newList = newarr.filter((elt,i)=>{
    return i=== index
  })

  let data=[]
  for(let i=0; i<prescData.length; i++) {
    if(prescData[i].prescription_id===newList[0].prescription_id){
      prescData[i].image.map(item=>{
            data= data.concat(item)
        })
      setImg(data)
      
      break;
    }
    else {
      setImg([])
    }
  }

  
  setImageShow(true)
  
  
}

const ViewPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ViewRemark data={remarkData} url={url} />

      </Modal.Body>
    </Modal>
  );
}

const handleView =(data,url) => {
 
  setUrl(url)
  setRemarkData(data)
  setViewShow(true)
}


const renderEditableFreq = (cellInfo) => {


  return (
    <div style={{ textTransform: "lowercase",padding:"1%",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":"" }}

    > {medList[cellInfo.index][cellInfo.column.id]!==null?medList[cellInfo.index][cellInfo.column.id]:(medList[cellInfo.index].date+" "+medList[cellInfo.index].time.slice(0,5))}</div>)
}

const renderEditableQty=(cellInfo)=>{


  return(
    <div style={{padding:"1%",textTransform:"lowercase",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":""}}
        
      > {medList[cellInfo.index][cellInfo.column.id]}{" "}{medList[cellInfo.index].intake_unit}</div> )
}
const renderEditable=(cellInfo)=>{


  return(
    <div style={{textTransform:"lowercase",padding:"1%",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":""}}
        
      > {cellInfo.column.id==="form"||cellInfo.column.id==="route"?(medList[cellInfo.index][cellInfo.column.id]&&medList[cellInfo.index][cellInfo.column.id]!==""&&(medList[cellInfo.index][cellInfo.column.id]).replace(/_/g, ' ').replace(/-/g, ' ')):medList[cellInfo.index][cellInfo.column.id]}</div> )
}

const renderATC=(cellInfo)=>{


  return(
    <div style={{padding:"1%",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":""}}
        
      > {medList[cellInfo.index][cellInfo.column.id]}</div> )
}
const renderEditableCr=(cellInfo)=>{


  return(
    <div  style={{padding:"1%",textTransform:"capitalize",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":""}}
        
      > {medList[cellInfo.index][cellInfo.column.id]}
      <br/><small>{medList[cellInfo.index].creator_type}</small></div> )
}

const AddPopup = (props) => {


  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="remark-width"
    >
        <div className="row" style={{width:"100%",marginTop:"2%",paddingLeft:"2%"}}><h6 className="col-11" style={{display:"flex"}}>Remarks- {infoData1.drug.charAt(0).toUpperCase() + (infoData1.drug).slice(1).toLowerCase()}</h6>
        <svg onClick={() => {setRefresh(!refresh);setModalShow(false);}} className="col-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"/></svg></div>
      
      <Modal.Body className="">
        <AddRemark    trackId={infoData1.id}   />

      </Modal.Body>
     
    </Modal>
  );
}
const renderEditableDuration=(cellInfo)=>{
let day = 'day';
if (medList[cellInfo.index][cellInfo.column.id] && parseInt(medList[cellInfo.index][cellInfo.column.id]) > 1) {
  day = 'days'
}
  return(
    !medList[cellInfo.index].is_emergency&& <div style={{padding:"1%",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":""}}
      
      > {medList[cellInfo.index][cellInfo.column.id]} {medList[cellInfo.index].duration_unit} </div> )
}




  const deleteMedicineRow = (index)=>{

    setDeleteMedConfirmMsg('success');
    setDeleteMedConfirmModalShow(true);
    setMedIndexToDelete(index);
     
  }
  const handleEdit = (data)=>{
  setDataList(data)
  setEditShow(true)
  }
  const renderDrug=(cellInfo)=>{

    return(<div style={{display:"flex"}}>
     <div style={{padding:"1%",color:medList[cellInfo.index].is_emergency&&medList[cellInfo.index].is_paused?"#cea59d":medList[cellInfo.index].is_paused?"silver":medList[cellInfo.index].is_emergency?"#3300FE":""}}
          className=""
        > {(medList[cellInfo.index][cellInfo.column.id]).charAt(0).toUpperCase() + (medList[cellInfo.index][cellInfo.column.id]).slice(1).toLowerCase()} </div></div> )
  }



const columns = [

  {
    Header: "",
    Cell: props=>{
      return (<div >
     <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Delete</Tooltip>}> 
     <svg style={{cursor:"pointer"}} onClick={() => { deleteMedicineRow(props.index) ;setAction("del")}} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="red" d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"/></svg>
     </OverlayTrigger>
     &nbsp;&nbsp;&nbsp;
     {medList[props.index].is_paused?
      <OverlayTrigger   overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Start</Tooltip>}>
      <svg onClick={() => { deleteMedicineRow(props.index) ;setAction("start")}} style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 256 256"><path fill="green" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m40.55 110.58l-52 36A8 8 0 0 1 104 164V92a8 8 0 0 1 12.55-6.58l52 36a8 8 0 0 1 0 13.16"/></svg>
      </OverlayTrigger>:
      <OverlayTrigger   overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Pause</Tooltip>}>
      <svg  onClick={() => { deleteMedicineRow(props.index) ;setAction("pause")}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="orangered" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2m-1 14H9V8h2zm4 0h-2V8h2z"/></svg>
      </OverlayTrigger>}
        &nbsp;&nbsp;&nbsp;
        {medList[props.index].pharmacy_status==="approved"?<OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Available</Tooltip>}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 10 10"><path fill="green" d="M4.998 0a4.998 4.998 0 1 0 0 9.995a4.998 4.998 0 0 0 0-9.995ZM1 4.998a3.998 3.998 0 1 1 7.995 0a3.998 3.998 0 0 1-7.995 0Zm6.104-1.602a.5.5 0 0 1 0 .708l-2.25 2.25a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647l1.896-1.897a.5.5 0 0 1 .708 0Z"/></svg></OverlayTrigger>
        : medList[props.index].pharmacy_status === "partially_approved" ? <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Partially Available</Tooltip>}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 10 10"><path fill="blue" d="M4.998 0a4.998 4.998 0 1 0 0 9.995a4.998 4.998 0 0 0 0-9.995ZM1 4.998a3.998 3.998 0 1 1 7.995 0a3.998 3.998 0 0 1-7.995 0Zm6.104-1.602a.5.5 0 0 1 0 .708l-2.25 2.25a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647l1.896-1.897a.5.5 0 0 1 .708 0Z" /></svg></OverlayTrigger>
        :medList[props.index].pharmacy_status==="pending"?<OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Pending</Tooltip>}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32"><circle cx="9" cy="16" r="2" fill="blue"/><circle cx="23" cy="16" r="2" fill="currentColor"/><circle cx="16" cy="16" r="2" fill="blue"/><path fill="blue" d="M16 30a14 14 0 1 1 14-14a14.016 14.016 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12.014 12.014 0 0 0 16 4"/></svg></OverlayTrigger>
        :<OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">not  Available</Tooltip>}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="red" d="M12 20a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm0-16a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm-8 8a7.97 7.97 0 0 1 2.343-5.657L4.93 4.93A9.972 9.972 0 0 0 2 11.999h2Zm2.343-5.657A7.972 7.972 0 0 1 12 4V2a9.972 9.972 0 0 0-7.071 2.929l1.414 1.414Zm-1.414 0l12.728 12.728l1.414-1.414L6.343 4.929L4.93 6.343ZM20 12a7.97 7.97 0 0 1-2.343 5.657l1.414 1.414A9.972 9.972 0 0 0 22 12h-2Zm-2.343 5.657A7.972 7.972 0 0 1 12 20v2a9.972 9.972 0 0 0 7.071-2.929l-1.414-1.414Z"/></svg></OverlayTrigger>}
        &nbsp;&nbsp;&nbsp;

        <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">med interaction</Tooltip>}>
        <svg 
        onClick={()=>{
          if((medList[props.index].med_interactions !== "null" && medList[props.index].med_interactions !== "" && medList[props.index].med_interactions !== null) ||
          (medList[props.index].med_url !== "null" && medList[props.index].med_url !== "" && medList[props.index].med_url !== null)){
            setInfoData1(medList[props.index]);
            setinfoShow(true);setdrugInt(true)
          }
          
        
        }}
        style={{cursor:(medList[props.index].med_interactions!=="null"&&medList[props.index].med_interactions!==""&&medList[props.index].med_interactions!==null)||
    (medList[props.index].med_url!=="null"&&medList[props.index].med_url!==""&&medList[props.index].med_url!==null)?"pointer":"not-allowed"}} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill={(medList[props.index].med_interactions!=="null"&&medList[props.index].med_interactions!==""&&medList[props.index].med_interactions!==null)||
    (medList[props.index].med_url!=="null"&&medList[props.index].med_url!==""&&medList[props.index].med_url!==null)?"Highlight":"gray"} d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3M12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1m1 4h-2v-2h2z"/></svg>
    </OverlayTrigger>
    &nbsp;&nbsp;&nbsp;
    <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Remarks</Tooltip>}>
      
      
         <svg onClick={() =>{setInfoData1(medList[props.index]); setModalShow(true);}} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path d="M20 2H10a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v8h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" fill="rgb(42, 99, 121)"/><path d="M4 22h10c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2zm2-10h6v2H6v-2zm0 4h6v2H6v-2z" fill="rgb(42, 99, 121)"/></svg>
     </OverlayTrigger>
    &nbsp;&nbsp;&nbsp;
    <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Info</Tooltip>}>
    <svg onClick={()=>{setInfoData1(medList[props.index]);setdrugInt(false);setinfoShow(true) }} style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 256 256"><path fill="#FF9C01" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m-4 48a12 12 0 1 1-12 12a12 12 0 0 1 12-12m12 112a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 0 16"/></svg>
    </OverlayTrigger>
    &nbsp;&nbsp;&nbsp;
    <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Edit</Tooltip>}> 
    <svg style={{cursor:"pointer"}}xmlns="http://www.w3.org/2000/svg"onClick={()=>handleEdit(medList[props.index]) } width="1.5em" height="1.5em" viewBox="0 0 24 24"><g fill="var(--theme-blue)" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352z"/><path d="M19.846 4.318a2.2 2.2 0 0 0-.437-.692a2 2 0 0 0-.654-.463a1.92 1.92 0 0 0-1.544 0a2 2 0 0 0-.654.463l-.546.578l2.852 3.02l.546-.579a2.1 2.1 0 0 0 .437-.692a2.24 2.24 0 0 0 0-1.635M17.45 8.721L14.597 5.7L9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.5.5 0 0 0 .255-.145l4.778-5.06Z"/></g></svg>
   </OverlayTrigger> </div>
     
  )
    },
   
    sortable:false,
    filterable:false,
    width:220,
   
  },
{
  Header: "Drug",
  accessor: "drug",
  Cell: renderDrug,

  minWidth:100,
   sortable:false,
   filterable:false,
   width:300

},

{
  Header: "ATC Code",
  accessor: "med_code",
  // minWidth:80,
  // width:110,
  Cell: renderATC,
  sortable:false,
  filterable:false

},
{
  // Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
  //              data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  Header: "Intake Period",
  accessor: "intake",
  Cell: renderEditable,
  sortable:false,
  // width:100,
  // minWidth:100,
  filterable:false

},

{
  Header:"Quantity",
  accessor: "intake_count",
  // minWidth:100,
  // width:100,
  Cell: renderEditableQty,
  sortable:false,
  filterable:false

},
// {
//   Header:"Unit",
//   accessor: "intake_unit",
//   minWidth:100,
//   width:80,
//   Cell: renderIntakeUnit,
//   sortable:false,
//   filterable:false

// },

// {
  // Header: "Intake Time",
  // accessor: "intake_time",
  // minWidth:120,
  // width:150,
  // Cell: renderIntakeCount,
  // sortable:false,
  // filterable:false

// },
// {
//   Header: "TO",
//   accessor: "to",
//   minWidth:80,
//   width:120,
//   Cell: renderEditable,
//   sortable:false,
//   filterable:false

// },
{
  Header: "Frequency",
  accessor: "frequency",
  // minWidth:140,
  // width:130,
  Cell: renderEditableFreq,
  sortable:false,
  filterable:false

},

{
  Header: "Duration",
  accessor: "duration",
  // minWidth:80,
  // width:107,
  Cell: renderEditableDuration,
  sortable:false,
  filterable:false

},
{
  Header: "Prescribed by",
  accessor: "creator",
  Cell: renderEditableCr,
  
  
  // minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},

// {
//   Header: "Remarks",
//   accessor: "remarks",
//   Cell: renderRemarks,
//   sortable:false,
//   width:120,
//   minWidth:70,
//   filterable:false

// },
// {
//   Header: "",
//   accessor: "edit",
//   Cell: renderEdit,
//   sortable:false,
//   width:50,
//   minWidth:100,
//   filterable:false

// },
]





const handleDeleteMed=()=>{


  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    
let dataToSend={}
if(action==="del"){
dataToSend={
  is_deleted:"True"
}
}else if(action==="pause"){
  dataToSend={
    is_paused:"True"
  }
}else if(action==="start"){
  dataToSend={
    is_paused:"False"
  }
}
    axios.put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${medList[medIndexToDelete].id}/`, dataToSend,{ headers: {
      "Content-Type": "application/json",
      
      Authorization : 'Token '+str,
  }


    })



    .then(res=>{

       if(res.status==200){
        if(action==="start"){
          axios
          .post(`${APIURL}/api/v1/doctor/schedule-runner/?patient_id=${id}&schedule=medicine`,{},{
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + str,
            },
          })
        }
    alert.success(action==="del"?"Deleted successfully":res.data.message)
    setMedIndexToDelete(null)
    setRefresh(!refresh)
   }
 else{
     setSubmitMsg("error");
    
   
   }

    })
    .catch(err=>{
  
  setSubmitMsg("error");

})


 }

const DeleteMedConfirmPopup =(props)=>{
    

  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
>
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {action==="del"? <h4 id="confirm-reject">Please confirm to delete current medicine</h4>:
      action==="pause"?<h4 id="confirm-reject">Please confirm to pause current medicine</h4>:
      action==="start"?<h4 id="confirm-reject">Please confirm to start current medicine</h4>:""}
                   
                </Modal.Body>
      
      <Modal.Footer>
                   

                   <Button
                       variant="danger"
                       onClick={()=>{
    
                         setDeleteMedConfirmModalShow(false);
                           handleDeleteMed();
                           }}
                   >
                       {" "}
                       Confirm
                   </Button>
               </Modal.Footer>
    </Modal>
  );
}

  return(
    <div className="overlay-body">


    <div className="add-presc-page">
   

<div className="current-table-holder" style={{ marginTop: "1%" }}>
    <ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {5}
         filterable
         resizable
         noDataText={"No data"}
         className="observation-table"
         >

         </ReactTable>
         <br/>
       
  </div>
       
          
        
        
      </div>
     

{deleteMedConfirmMsg==="success" ? 

<DeleteMedConfirmPopup
show={deleteMedConfirmModalShow}
onHide={() =>  setDeleteMedConfirmModalShow(false)}
index= {medIndexToDelete}
/>

 : 
   '' } 
{
    editShow ? 
      <EditPopup
        show={editShow}
        onHide= {() => { setEditShow(false)     
        }}
      /> : null
    }
{
      viewShow ? 
      <ViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false)     
        }}
      /> : null
    }
   {infoShow ? (
                <InfoPopup1
                    show={infoShow}
                    onHide={() => {
                        setinfoShow(false);
                    }}
                />
            ) : (
                ""
            )}
            {
        modalShow ?
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          /> : null
      }
</div>    
)
}

export default CurrentMedications;