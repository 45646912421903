import React, { useState, useEffect } from "react";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useAlert } from "react-alert";
import { DatePicker as DatePicker1 } from 'rsuite';
import DatePicker from 'react-date-picker';
import moment, { invalid } from "moment";
import 'rsuite/dist/rsuite.min.css'; // Importing RSuite CSS

function EditMedicine(data) {
  const [hr, setHr] = useState()
  console.log(data.data)
  const alert = useAlert();
  const [date, setDate] = useState(data.data.frequency===null ? new Date(data.data.date) : null)
  const [time, setTime] = useState(data.data.frequency===null ? new Date(data.data.date + " " + data.data.time) : null)
  const [frequency, setFrequency] = useState(data.data.frequency !== null && data.data.frequency !== "" ? data.data.frequency : "")
  const [intakeTime, setIntakeTime] = useState(data.data.intake_time)
  const [duration, setDuration] = useState(data.data.duration)
  const [durationUnit, setDurationUnit] = useState({ label: data.data.duration_unit, value: data.data.duration_unit })

  const [intakeCount, setIntakeCount] = useState(data.data.intake)
  const [intakeunit, setIntakeUnit] = useState({ label: data.data.intake_unit, value: data.data.intake_unit })
  const [dosage, setDosage] = useState(data.data.intake_count)
  const [remark, setRemark] = useState(data.data.remarks)

  const [options, setOptions] = useState([])



  const intakecountoptions = [
    { value: "", label: "" },
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },

  ];

  const freqOptions = [
    { value: "", label: "" },
    { value: "0-0-0-1", label: "0-0-0-1" },
    { value: "0-0-1-0", label: "0-0-1-0" },
    { value: "0-0-1-1", label: "0-0-1-1" },
    { value: "0-1-0-0", label: "0-1-0-0" },
    { value: "0-1-0-1", label: "0-1-0-1" },
    { value: "0-1-1-0", label: "0-1-1-0" },
    { value: "0-1-1-1", label: "0-1-1-1" },
    { value: "1-0-0-0", label: "1-0-0-0" },
    { value: "1-0-0-1", label: "1-0-0-1" },
    { value: "1-0-1-0", label: "1-0-1-0" },
    { value: "1-0-1-1", label: "1-0-1-1" },
    { value: "1-1-0-0", label: "1-1-0-0" },
    { value: "1-1-0-1", label: "1-1-0-1" },
    { value: "1-1-1-0", label: "1-1-1-0" },
    { value: "1-1-1-1", label: "1-1-1-1" },
  ];
  const durationOptions = [
    { value: "", label: "" },
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
  ];
  const durationOptions1 = [
    { value: "", label: "" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
  ];

  const durationOptions2 = [
    { value: "", label: "" },

    { value: "months", label: "Months" },
  ];
  const intakeTimeOptions = [
    { value: "", label: "" },
    { value: "before food", label: "Before food" },
    { value: "after food", label: "After food" },
  ];

  const handleDate = (date) => {

    setDate(date)

    const a = date !== null ? moment(date).format('YYYY-MM-DD') : null;
    setTime(null)
    setFrequency("")
    // setMedicine(currentstate => ({
    //   ...currentstate,
    //   date: a !== null ? a : "",
    //   time: null,
    //   frequency:""
    // }));

  }
  const handleTime = (time1) => {
    if(moment(date).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')){
      if(moment(time1).format('HH:mm')<moment(new Date()).format('HH:mm')){
        alert.error("past time is not permitted")
        setTime(null)
      }
      else{
        setTime(time1)
        setFrequency("")
      }
    }
    else{
      setTime(time1)
      setFrequency("")
      }
   
   
  }
  useEffect(() => {
    if (date!==null&&date === moment(new Date()).format('YYYY-MM-DD') ) {
    setHr(new Date().getHours())
    
             
            }
            else {
                setHr(null)
              
            }
        }, [date])
  const IntakeUnitOptions1 = [
    { value: "", label: "" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "nos", label: "number" },
    { value: "shots", label: "shots" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "cc", label: "cc" },
    { value: "g", label: "g" },
    { value: "puffs", label: "puffs" },

  ];
  const IntakeUnitOptions2 = [
    { value: "", label: "" },
    { value: "ml", label: "mL" },
    { value: "cc", label: "cc" },
    { value: "shots", label: "shots" }
  ];
  const IntakeUnitOptions3 = [
    { value: "", label: "" },
    { value: "ml", label: "mL" },
    { value: "puffs", label: "puffs" }
  ]
  const IntakeUnitOptions4 = [
    { value: "", label: "" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" }
  ]

  const IntakeUnitOptions5 = [
    { value: "", label: "" },
    { value: "nos", label: "number" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "g", label: "g" }
  ]
  const IntakeUnitOptions6 = [
    { value: "", label: "" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" }
  ]
  const IntakeUnitOptions7 = [
    { value: "", label: "" },
    { value: "nos", label: "number" }
  ]
  useEffect(() => {
    if (intakeCount === "Daily" || intakeCount === "daily") {
      setOptions(durationOptions)
    }
    else if (intakeCount === "Weekly" || intakeCount === "weekly") {
      setOptions(durationOptions1)
    }
    else if (intakeCount === "Monthly" || intakeCount === "monthly") {
      setOptions(durationOptions2)
    }
  }, [intakeCount])
  const handleChange = (e) => {
    const val = e.target.value;
    const key = e.target.label;

    setDurationUnit({ key: val, value: val })

  }
  const handleChange1 = (e) => {
    const val = e.target.value;


    setIntakeUnit({ label: val, value: val })

  }


  const handleSearchChange = (e) => {
    const val = e.target.value;
    const key = e.target.name;
    if (key === "frequency") {
      setFrequency(val)
    }
    if (key === "intakeTime") {
      setIntakeTime(val)
    }
    if (key === "period") {

      setDuration(val)


    }

    if (key === "intakeUnitValue") {

      setIntakeUnit(val)


    }
    if (key === "intakeCount") {
      setDurationUnit({ key: "", value: "" })
      setIntakeCount(val)
    }
    if (key === "dosage") {
      setDosage(val)
    }
    if (key === "remark") {
      setRemark(val)
    }
  }
  const medicineSubmit = (MedData) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    if (data.data.is_emergency && frequency === "" && date === null && time === null) {
      alert.error("*Frequency / Date & time is mandatory!");
    }
    else if (data.data.is_emergency && frequency === "" && (date === null || time === null)) {
      alert.error("*Frequency / Date & time is mandatory!");
    }
    else if (dosage < 1) {
      alert.error("*quantity is mandatory!");
    }
    else if (!data.data.is_emergency && duration < 1) {
      alert.error("*Please choose a valid duration!");

    }
    else if (!data.data.is_emergency && durationUnit.value === "") {
      alert.error("*Please choose a valid duration!");
    }


    else {
      let dataToSend = {}
      if (data.data.is_emergency && date === null) {
        dataToSend = {
          frequency: frequency,
          intake_count: dosage,
          duration:0,
          remarks: remark,
          intake_unit: intakeunit.value
        };
      }
      else if (data.data.is_emergency) {
        dataToSend = {
          duration:0,
         
          remarks: remark,
          intake_count: dosage,
          intake_unit: intakeunit.value,
          date: moment(date).format('YYYY-MM-DD'),
          time: moment(time).format('HH:mm:ss')
        };
      }
      else {
        dataToSend = {
          frequency: frequency,
          intake: intakeCount,
          intake_time: intakeTime,
          duration: duration,
          duration_unit: durationUnit.value,
          intake_count: dosage,
          remarks: remark,
          intake_unit: intakeunit.value
        };
      }
      axios
        .put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${data.data.id}/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + str,
          },
        })
        .then((res) => {

          if (res.data.status === "success") {
            alert.success("Updated successfully")
            data.modalClose()


          } else {
            alert.error(res.data.message);

          }
        })
        .catch((err) => {
          alert.error("error");

        });
    }
  };
  const handleCancel = () => {
    data.modalClose();
  };

  return (
    <>

      <div className="flex-head">
        <h4 className="title-of-tasks title-mg"> Edit Medicines</h4>
        <h5 className="title-of-tasks"> {data.data.medicine_name}</h5>
      </div>
      <br />

      <div className=" ">
        <div className="" >
          <div style={{ display: "flex", textAlign: "left" }}>

            <label className="col-4">{data.data.is_emergency&&data.data.frequency===null ? "*Date & time :" : "*Frequency :"}</label>
            <div className=" col-7">
            {data.data.frequency!==null && <select
                style={{ width: "100%" }}
                name="frequency"
                type="text"
                className=" css-1s2u09g-control1"
                value={frequency}

                onChange={(data) => {
                  setFrequency(data.target.value);
                  setDate(null);
                  setTime(null)
                }}
                options={freqOptions}
              >
                {freqOptions.map((item, index) => (
                  <option hidden={index === 0} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>}
              {data.data.is_emergency &&data.data.frequency===null&& <div className="row">
                <div className="col-6">
                  <DatePicker

                    style={{ border: "none", }}
                    className=" css-1s2u09g-control1"
                    appearance="default"
                    format="yyyy-MM-dd"
                    minDate={new Date()}
                    onChange={date => { handleDate(date) }}
                    // disabledDate={allowedRange(admissionDate, new Date())}
                    onClear={() => { }}
                    value={date}



                  />
                </div>
                <div className="col-6">
                  <DatePicker1
                    disabled={date === ""}
                    style={{ border: "none" }}
                    className="css-1s2u09g-control1"
                    appearance="default"
                    format="HH:mm"
                    onChange={time => { handleTime(time) }}
                    disabledHours={hour => hour < hr}
                    onClear={() => { }}
                    value={time}


                  />
                </div>
              </div>}

            </div>


          </div><br />
          {!data.data.is_emergency && <><div style={{ display: "flex", textAlign: "left" }}>

            <label className="col-4">Intake Time :</label>
            <div className=" col-7">
              <select
                style={{ width: "100%" }}
                name="intakeTime"
                type="text"
                className=" css-1s2u09g-control1"

                value={intakeTime}
                defaultInputValue={intakeTime}
                onChange={handleSearchChange}
                options={intakeTimeOptions}
              >
                {intakeTimeOptions.map((item, index) => (
                  <option  value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div><br />
            <div style={{ display: "flex", textAlign: "left" }}>

              <label className="col-4">*Intake Period :</label>
              <div className=" col-7">
                <select
                  style={{ width: "100%" }}
                  name="intakeCount"
                  className=" css-1s2u09g-control1"
                  type="text"

                  value={intakeCount}
                  // defaultInputValue={intakecount_label}
                  onChange={handleSearchChange}
                  options={intakecountoptions}
                >
                  {intakecountoptions.map(item => (
                    <option value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div><br />
            <div className="" style={{ display: "flex", textAlign: "left" }}>

              <label className="col-4">*Duration :</label>
              <div className="col-7"  >

                <div className="row">
                  <div className=" col-5">
                    <input
                      name="period"
                      type="number"
                      className=" css-1s2u09g-control1"
                      min="1"
                      value={duration}

                      style={{ width: "100%" }}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className=" col-7">

                    <select
                      name="durationValue"
                      type="text"
                      style={{ width: "100%" }}
                      className=" css-1s2u09g-control1"

                      value={durationUnit.value}
                      onChange={handleChange}
                    // options={options}
                    >
                      <option hidden value="" ></option>
                      {options.map((item) => {
                        return (
                          <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div></div><br /></>}

          <div style={{ display: "flex", textAlign: "left" }}>

            <label className="col-4">*Quantity:</label>
            <div className=" col-3">
              <input
                name="dosage"
                style={{ marginLeft: "1%", width: "105%" }}
                type="number"
                className=" css-1s2u09g-control1"
                min="1"
                max="200"

                value={dosage}
                defaultInputValue={dosage}
                onChange={handleSearchChange}

              />
            </div>
            <div className=" col-4">
              <select

                name="intakeUnitValue"
                type="text"
                style={{ width: "100%" }}

                value={intakeunit.value}
                className=" css-1s2u09g-control1"
                onChange={handleChange1}

              >
                <option hidden value="" ></option>
                {data.data.form && (data.data.form.toLowerCase().includes("capsule") || data.data.form.toLowerCase().includes("chewable") || data.data.form.toLowerCase().includes("suppositor") || data.data.form.toLowerCase().includes("tablet")) ? IntakeUnitOptions7.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                }) : data.data.form && (data.data.form.toLowerCase().includes("solution") || data.data.form.toLowerCase().includes("emulsion") || data.data.form.toLowerCase().includes("lotion")) ? IntakeUnitOptions6.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                }) : data.data.form && (data.data.form.toLowerCase().includes("suspension") || data.data.form.toLowerCase().includes("cream") || data.data.form.toLowerCase().includes("ointment")) ? IntakeUnitOptions4.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                }) : data.data.form && (data.data.form === "effervescent granules" || data.data.form.toLowerCase().includes("granul")) ? IntakeUnitOptions5.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                }) : data.data.form && (data.data.form === "aerosols" || data.data.form === "gases" || data.data.form.toLowerCase().includes("aerosol") || data.data.form.toLowerCase().includes("gas")) ? IntakeUnitOptions3.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                }) : data.data.form && (data.data.form === "injections" || data.data.form.toLowerCase().includes("injection")) ? IntakeUnitOptions2.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                }) : IntakeUnitOptions1.map((item) => {
                  return (
                    <option hidden={item.value === ""} value={item.value} >{item.label}</option>
                  )
                })}
              </select>
            </div>
          </div><br />
         
        

          <div style={{ marginTop: '20px', display: 'flex', float: "right", }}>
            <button onClick={handleCancel} className="btn btn-secondary mb-2 mr-2" >
              Cancel
            </button>
            <button onClick={medicineSubmit} type="submit" className="btn btn-primary btn-col mb-2" >
              Save
            </button>
          </div>

        </div>
      </div>


    </>
  );
}

export default EditMedicine;
