import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import "./navbar.css";
import axios from "axios";
import { APIURL,TZ } from "../../Global";
import { LoginContext } from "../contexts/LoginContext";
import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import logo from "../../assets/images/cp_logo.jpg";
import { ProfileContext } from "../contexts/ProfileContext";
import { NavDropdown } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import profile from "../../assets/images/icons/profile.png";
import CallforAction from "../doctor/CallforAction";
import { SOCKETURL } from "../../Global";
import { RiUserLine } from "react-icons/ri";
// import orgData from "../../felixaconfig.json"

// import { useTranslation } from "react-i18next";

function Navbar(orgData) {
  const { state } = useLocation()
  const from = state!==null?state.from:"";
  const webSocket = useRef(null);
  const [count, setCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);
  const [img, setImg] = useState("");
  const [appName, setAppName] = useState();
  const [appLogo, setAppLogo] = useState("");
  const [loginDatas] = useContext(LoginContext);
  const navigate = useNavigate();
  const [render] = useContext(ProfileContext);
  const [location, setLocation] = useState();
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const [actionshow, setActionShow] = useState(false);
  const [serveCount, setServeCount] = useState("");
  const [aggregator, setAggregator] = useState('');
  const [regcount, setRegCount] = useState(sessionStorage.getItem("reg")?sessionStorage.getItem("reg"):0);
  const projType = sessionStorage.getItem("project_type")
  const [notification, setNotification] = useState();
  const [read, setRead] = useState(false)
  const [logoFlag, setLogoFlag] = useState(false)
  const [features, setFeatures] = useState([])
  const confirmButtonRef = useRef()

  useEffect(() => {
    const handleKey = (e) => {
     
      if ((e.altKey && e.key === 'c') && confirmButtonRef.current) {
        e.preventDefault()
        setLogoutConfirm(false)
      } else if (e.key === 'Enter' && confirmButtonRef.current) {
        e.preventDefault()
        confirmButtonRef.current.click()
      }

    }
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    }

  }, [confirmButtonRef.current]);
  useEffect(() => {


    axios.get(`${APIURL}/api/v1/account/customer-info/?${TZ}`,

    )
      .then(res => {
        if (res.data.status === "success") {
          setAppName(res.data.message.app_name)
          setAppLogo(res.data.app_logo)

          if (res.data.app_logo !== APIURL + "/media/null" && res.data.app_logo !== "") {
            setLogoFlag(true)
          }
          setServeCount(Number(res.data['service provider count']))
          setAggregator(res.data.aggregation_type)
          setRegCount(res.data.registered_sp_count)
          let arr = []
          res.data.features.length > 0 && res.data.features.map((i) => {
            arr.push(i.feature_name)
          })
          setFeatures(arr)


        }
      })
  }, []);
  const loadFunc = async (page) => {

    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');


    await axios.get(`${APIURL}/api/v1/account/web-notification/?per_page=1&page=1&${TZ}
          `,
      {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Token ' + str
        }
      })
      .then(res => {

        if (res.data.status === "success") {

          setNotification(res.data.unread_count)
        }

      })


  }
  useEffect(() => {
    sessionStorage.getItem('usertoken') && loadFunc()

  }, [read, loginDatas !== null]);
  useEffect(() => {
    const timer = setInterval(loadFunc, 900000);
    return () => clearInterval(timer);
  }, []);




  const ref = useRef(null);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas")) || null
  );
  useEffect(() => {

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));

  }, [loginDatas !== null]);


  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/login");
  };

  const handleOpen = () => {
    setShow(!show);
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };
  const handleLogout1 = () => {
    setLogoutConfirm(true);
  };

  const handleLogout = () => {
    setLogoutConfirm(false);
    callLogout();
    setIsLoggedIn(false);
    setNotification(0)
    setImg("")
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
  };

  if (sessionStorage.getItem("usertoken")) {
    setIsLoggedIn(true);
  }

  const LogoutConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >

        <Modal.Body >


          <h5 className="title-of-page" > Please Confirm to Logout </h5><br />

          <div className="form-group ">
            <button type="button" className="btn btn-danger btn-cancel" onClick={props.onHide}> Cancel </button>
            <button type="submit" className="btn btn-primary btn-col" ref={confirmButtonRef} onClick={handleLogout} >Confirm </button>
          </div>
        </Modal.Body>

      </Modal>
    );
  };
  useEffect(() => {
    if (login_datas) {
      handlingWebsocket();
    }
  }, [login_datas]);

  const handlingWebsocket = (props) => {

    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
  
    const docId=login_datas.user_id;
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(

      `${SOCKETURL}/ws/call_action/action_spuser_${login_datas.user_type === "hospital_doctor"?docId:hospId}/?token=` +
      str

    );
    webSocket.current.onopen = () => {
      console.log("WebSocket connected");

    };

    webSocket.current.onmessage = (evt) => {

      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);

      setCount(message.message.ca_action_count);
      // if (message.message.message_type === "global_call_for_action") {
      //   // setUpdate(true)
      // }
    };

    webSocket.current.onclose = () => {
      console.log("WebSocket disconnected");

    };
  }

  const ActionPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >

        <Modal.Body >
          <h6 style={{ marginTop: "-10px", marginLeft: "-10px", marginRight: "1%", padding: "0.5%", boxShadow: "0 3px 5px -3px rgba(0, 0, 0, 0.25)" }}><b>Call for action</b>
            <button style={{ backgroundColor: "white", float: "right" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times" style={{ color: "black" }} aria-hidden="true"></i>
            </button></h6>
          <CallforAction from="all" onClose={props.onHide} />

        </Modal.Body>

      </Modal>
    );
  };


  useEffect(() => {
    if (isLoggedIn === true) {
      if (login_datas !== null) {
        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_pharmacist" || login_datas.user_type === "hospital_labuser" || login_datas.user_type === "hospital_allied_prof" || login_datas.user_type === "hospital_nurse" || login_datas.user_type === "hospital_bdo" || login_datas.user_type === "hospital_manager" || login_datas.user_type === "hospital_pro" || login_datas.user_type === "hospital_carer") {
          const tokenString = sessionStorage.getItem("usertoken");

          let str = tokenString.replace(/["]+/g, "");

          axios
            .get(`${APIURL}/api/v1/account/profile`, {
              headers: {
                Authorization: "Token " + str,
              },
            })
            .then((res) => {
              if (res.data.status === "success") {
                setImg(res.data.data.photo);
              }
            })
            .catch((err) => { });
        }

        else if (
          login_datas.user_type === "hospital" &&
          login_datas.verification_status === "approved"
        ) {
          const tokenString = sessionStorage.getItem("usertoken");

          let str = tokenString.replace(/["]+/g, "");

          axios
            .get(`${APIURL}/api/v1/service-provider/hospital-profile/`, {
              headers: {
                Authorization: "Token " + str,
              },
            })
            .then((res) => {
              if (res.data.status === "success") {
                setImg(res.data.hospital_profile.photo);
                setLocation(res.data.hospital_profile.location);

              }
            })
            .catch((err) => { });
        }
      }
    }
  }, [render, isLoggedIn === true]);

  function format(names) {
    if (names && names !== "") {
      const words = names.split(" ");

      const capitalizedWords = words.map(word => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
      });
      return capitalizedWords.join(" ");
    }
  }
  return (
    <>
      <nav style={{
        position: "fixed", height: '65px',
        width: '100%', zIndex: "1050"
      }} className="nav-bar">
        <div className="logo-div col-4">
          {isLoggedIn ? (
            login_datas.user_type === "config_admin" ?
              <>

                <Link onClick={() => setRead(!read)} to="/">

                  <img className="logo-img" src={logo1} alt="Logo" />
                </Link>

                <Link onClick={() => setRead(!read)} to="/">
                  <img className="logo-img1" src={logo2} alt="Logo" />
                </Link>
              </>


              : <div style={{ display: "flex" }}>

                <h5 style={{ color: "white", textDecoration: "none",paddingTop:"5%" }}> <Link onClick={() => setRead(!read)} to="/"><img className="logo-img" src={logoFlag ? appLogo : logo1} alt="Logo" /></Link>
                  <span>&nbsp;&nbsp;</span><b>{appName ? appName : <img className="logo-img1" src={logo2} alt="Logo" />}</b></h5>
              </div>
          ) : <div style={{ display: "flex" }}>

            <h5 style={{ color: "white", textDecoration: "none",paddingTop:"5%" }}><Link onClick={() => setRead(!read)} to="/login"><img className="logo-img" src={logoFlag ? appLogo : logo1} alt="Logo" /></Link>
              <span>&nbsp;</span><b> {appName ? appName : <img className="logo-img1" src={logo2} alt="Logo" />}</b></h5>
          </div>}
        </div>
        <div className="btns" >
          {isLoggedIn ? (

            login_datas.user_type === "hospital_manager"
              ? (
                <>
                  <NavLink exact to="/hospital_manager" activeClassName="active" className="inactive">
                    <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavLink exact to="/patientlist" activeClassName="active" className="inactive">
                    <b>{"PATIENT RECORD"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {features.includes("call_for_action") && <><NavLink to={false} activeClassName="active" className="inactive">
                    <button onClick={() => { setCount(0); setActionShow(true) }} style={{ backgroundColor: "rgba(220, 132, 82, 1)", borderRadius: "5px" }}><span style={{ color: "white", borderRadius: "10px", fontSize: "14px", width: "20px", backgroundColor: "red" }} >&nbsp;{count}&nbsp;</span> CFA</button>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                
                  <NavDropdown
                    ref={ref}
                    style={{ zIndex: "1001" }}
                    title={
                      <>
                        <div style={{ display: "flex" }}>
                          <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                          {img ? <img
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                            src={img}
                            alt="Img"
                          ></img> : <div className="defaultprofile-nav">
                            <RiUserLine style={{ fontSize: '20px' }} />
                          </div>}
                        </div>
                      </>
                    }
                    id="collasible-nav-dropdown"
                    renderMenuOnMount={true}
                    noCaret

                    onClick={handleOpen}
                    show={show}
                  >
                    <button
                      className="btn login-btn logout-btn"
                      onClick={() => navigate("/doctor/profile")}
                    >
                      Profile
                    </button>
                    <button
                      className="btn login-btn logout-btn"
                      onClick={handleLogout1}
                    >
                      Logout
                    </button>
                  </NavDropdown>
                  <div>

                  </div>{" "}
                </>
              ) : login_datas.user_type === "hospital_doctor" ? (
                <>{projType === "home_care" ? <>
                  <NavLink exact to="/hospital_doctor" onClick={() => setRead(!read)} activeClassName="active" className="inactive">
                    <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavLink onClick={() => setRead(!read)} exact to="/patientlist" activeClassName="active" className="inactive">
                    <b>{"PATIENT RECORDS"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavLink onClick={() => setRead(!read)} exact to="/service-request" activeClassName="active" className="inactive">
                    <b>{"SERVICE FULFILLMENT"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  <NavLink to="/notifications" onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }}>
                    <div
                      style={{ pointerEvents: "none" }}
                      className="inactive notification"
                      activeClassName="active">
                      <i
                        className="fas fa-bell fa-1x"
                        aria-hidden="true"
                      ></i>
                      <span
                        style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                        className="badge"
                      >
                        {notification}
                      </span> </div>
                    {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                  </NavLink></> : <><NavLink exact to="/hospital_doctor" onClick={() => setRead(!read)} activeClassName="active" className="inactive">
                    <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavLink onClick={() => setRead(!read)} exact to="/patientlist" activeClassName="active" className="inactive">
                    <b>{"PATIENT RECORDS"}</b>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {features.includes("call_for_action") && <><NavLink to={false} activeClassName="active" className="inactive">
                    <button onClick={() => { setCount(0); setActionShow(true) }} style={{ backgroundColor: "rgba(220, 132, 82, 1)", borderRadius: "5px" }}><span style={{ color: "white", borderRadius: "10px", fontSize: "14px", width: "20px", backgroundColor: "red" }} >&nbsp;{count}&nbsp;</span> CFA</button>
                  </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                </>} <NavDropdown
                  ref={ref}
                  style={{ zIndex: "1001" }}
                  title={
                    <>
                      <div style={{ display: "flex" }}>
                        <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                        {img ? <img
                          style={{
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                          }}
                          src={img}
                          alt="Img"
                        ></img> : <div className="defaultprofile-nav">
                          <RiUserLine style={{ fontSize: '20px' }} />
                        </div>}
                      </div>
                    </>
                  }
                  id="basic-nav-dropdown"
                  renderMenuOnMount={true}
                  noCaret

                  onClick={handleOpen}
                  show={show}
                >
                    {/* <NavDropdown.Item>
                    <Link to="/doctor/profile">Profile</Link>
                  </NavDropdown.Item> */}
                    <button
                      className="btn login-btn logout-btn"
                      onClick={() => navigate("/doctor/profile")}
                    >
                      Profile
                    </button>
                    <button
                      className="btn login-btn logout-btn"
                      onClick={handleLogout1}
                    >
                      Logout
                    </button>
                  </NavDropdown>
                  <div>

                  </div>{" "}
                </>

              ) : login_datas.user_type === "hospital_labuser" ? (
                <>{projType === "home_care" ? <>
                  <NavLink onClick={() => setRead(!read)} exact to="/hospital_labuser" activeClassName="active" className="inactive">
                    <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                  </NavLink>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavLink onClick={() => setRead(!read)} exact to="/service-request" activeClassName="active" className="inactive">
                    <b>{"SERVICE FULFILLMENT"}</b>
                  </NavLink>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavLink to="/notifications" onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }}>
                    <div
                      style={{ pointerEvents: "none" }}
                      className="inactive notification"
                      activeClassName="active">
                      <i
                        className="fas fa-bell fa-1x"
                        aria-hidden="true"
                      ></i>
                      <span
                        style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                        className="badge"
                      >
                        {notification}
                      </span> </div>
                    {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                  </NavLink></> : ""}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <NavDropdown
                    ref={ref}
                    style={{ zIndex: "1001" }}
                    title={
                      <>
                        <div style={{ display: "flex" }}>
                          <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                          {img ? <img
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                            src={img}
                            alt="Img"
                          ></img> : <div className="defaultprofile-nav">
                            <RiUserLine style={{ fontSize: '20px' }} />
                          </div>}
                          {/* <i className="fas fa-user-circle user-icon"></i> */}
                        </div>
                      </>
                    }
                    id="basic-nav-dropdown"
                    renderMenuOnMount={true}
                    noCaret

                    onClick={handleOpen}
                    show={show}
                  >
                    {/* <NavDropdown.Item>
                    <Link to="/doctor/profile">Profile</Link>
                  </NavDropdown.Item> */}
                    <button
                      className="btn login-btn logout-btn"
                      onClick={() => navigate("/doctor/profile")}
                    >
                      Profile
                    </button>
                    <button
                      className="btn login-btn logout-btn"
                      onClick={handleLogout1}
                    >
                      Logout
                    </button>
                  </NavDropdown>
                  <div>

                  </div>{" "}
                </>
              ) :
                login_datas.user_type === "hospital_pharmacist" ? (
                  <>{projType === "elder_care" ? <>
                    <NavLink onClick={() => setRead(!read)} exact to="/hospital_pharmacist" activeClassName="active" className="inactive">
                      <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <NavLink onClick={() => setRead(!read)} exact to="/service-request" activeClassName="active" className="inactive">
                      <b>{"SERVICE FULFILLMENT"}</b>
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    {/* <NavLink onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }} to="/notifications">
                      <div
                        style={{ pointerEvents: "none" }}
                        className="inactive notification"
                        activeClassName="active">
                        <i
                          className="fas fa-bell fa-1x"
                          aria-hidden="true"
                        ></i>
                        <span
                          style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                          className="badge"
                        >
                          {notification}
                        </span> </div>
                      {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                    </NavLink> */}
                    </> 
                    : ""}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <NavDropdown
                      ref={ref}
                      style={{ zIndex: "1001" }}
                      title={
                        <>
                          <div style={{ display: "flex" }}>
                            <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                            {img ? <img
                              style={{
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                              }}
                              src={img}
                              alt="Img"
                            ></img> : <div className="defaultprofile-nav">
                              <RiUserLine style={{ fontSize: '20px' }} />
                            </div>}
                          </div>
                        </>
                      }
                      id="basic-nav-dropdown"
                      renderMenuOnMount={true}
                      noCaret

                      onClick={handleOpen}
                      show={show}
                    >
                      {/* <NavDropdown.Item>
                    <Link to="/doctor/profile">Profile</Link>
                  </NavDropdown.Item> */}
                      <button
                        className="btn login-btn logout-btn"
                        onClick={() => navigate("/doctor/profile")}
                      >
                        Profile
                      </button>
                      <button
                        className="btn login-btn logout-btn"
                        onClick={handleLogout1}
                      >
                        Logout
                      </button>
                    </NavDropdown>
                    <div>

                    </div>{" "}
                  </>
                ) :

                  login_datas.user_type === "config_admin" ? (
                    <>
                      <NavLink onClick={() => setRead(!read)} exact to="/config_admin" activeClassName="activeconfig" className="inactiveconfig">
                        <i class="fa fa-wrench" aria-hidden="true"></i> <b >{"CONFIGURATION"}</b>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <NavLink onClick={() => setRead(!read)} exact to="/admin_reset_pwd" activeClassName="activeconfig" className="inactiveconfig">
                        <i class="fa fa-cog" aria-hidden="true"></i>
                        <b>{"SETTINGS"}</b>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;



                      <NavDropdown
                        ref={ref}
                        style={{ zIndex: "1001" }}
                        title={
                          <>
                            <div style={{ display: "flex" }}>
                              <h6 className="user-name">{format(login_datas.name)}</h6>
                              {img ? <img
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                }}
                                src={img}
                                alt="Img"
                              ></img> : <img
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                }}
                                src={profile}
                                alt="Img"
                              ></img>}
                            </div>
                          </>
                        }
                        id="basic-nav-dropdown"
                        renderMenuOnMount={true}
                        noCaret

                        onClick={handleOpen}
                        show={show}
                      >
                        {/* <NavDropdown.Item>
                  <Link to="/doctor/profile">Profile</Link>
                </NavDropdown.Item> */}
                        <button
                          className="btn login-btn logout-btn"
                        // onClick={() => navigate("/doctor/profile")}
                        >
                          Profile
                        </button>
                        <button
                          className="btn login-btn logout-btn"
                          onClick={handleLogout1}
                        >
                          Logout
                        </button>
                      </NavDropdown>
                      <div>

                      </div>{" "}
                    </>
                  ) :
                    login_datas.user_type === "hospital_allied_prof" ? (
                      <>{projType === "home_care" ? <>
                        <NavLink onClick={() => setRead(!read)} exact to="/hospital_allied_prof" activeClassName="active" className="inactive">
                          <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink onClick={() => setRead(!read)} exact to="/patientlist" activeClassName="active" className="inactive">
                          <b>{"PATIENT RECORDS"}</b>
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink onClick={() => setRead(!read)} exact to="/service-request" activeClassName="active" className="inactive">
                          <b>{"SERVICE FULFILLMENT"}</b>
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }} to="/notifications">
                          <div
                            style={{ pointerEvents: "none" }}
                            className="inactive notification"
                            activeClassName="active">
                            <i
                              className="fas fa-bell fa-1x"
                              aria-hidden="true"
                            ></i>
                            <span
                              style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                              className="badge"
                            >
                              {notification}
                            </span> </div>
                          {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                        </NavLink></> : <NavLink to={false} activeClassName="active" className="inactive">
                        <button onClick={() => { setCount(0); setActionShow(true) }} style={{ backgroundColor: "rgba(220, 132, 82, 1)", borderRadius: "5px" }}><span style={{ color: "white", borderRadius: "10px", fontSize: "14px", width: "20px", backgroundColor: "red" }} >&nbsp;{count}&nbsp;</span> CFA</button>
                      </NavLink>}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavDropdown
                          ref={ref}
                          style={{ zIndex: "1001" }}
                          title={
                            <>
                              <div style={{ display: "flex" }}>
                                <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                                {img ? <img
                                  style={{
                                    borderRadius: "50%",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                  src={img}
                                  alt="Img"
                                ></img> : <div className="defaultprofile-nav">
                                  <RiUserLine style={{ fontSize: '20px' }} />
                                </div>}
                              </div>
                            </>
                          }
                          id="basic-nav-dropdown"
                          renderMenuOnMount={true}
                          noCaret

                          onClick={handleOpen}
                          show={show}
                        >
                          {/* <NavDropdown.Item>
                    <Link to="/doctor/profile">Profile</Link>
                  </NavDropdown.Item> */}
                          <button
                            className="btn login-btn logout-btn"
                            onClick={() => navigate("/doctor/profile")}
                          >
                            Profile
                          </button>
                          <button
                            className="btn login-btn logout-btn"
                            onClick={handleLogout1}
                          >
                            Logout
                          </button>
                        </NavDropdown>
                        <div>

                        </div>{" "}
                      </>
                    ) :
                      login_datas.user_type === "hospital_nurse" ? (
                        <>{projType === "home_care" ? <>
                          <NavLink onClick={() => setRead(!read)} exact to="/hospital_nurse" activeClassName="active" className="inactive">
                            <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                          </NavLink>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/service-request" activeClassName="active" className="inactive">
                            <b>{"SERVICE FULFILLMENT"}</b>
                          </NavLink>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }} to="/notifications">
                            <div
                              style={{ pointerEvents: "none" }}
                              className="inactive notification"
                              activeClassName="active">
                              <i
                                className="fas fa-bell fa-1x"
                                aria-hidden="true"
                              ></i>
                              <span
                                style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                                className="badge"
                              >
                                {notification}
                              </span> </div>
                            {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                          </NavLink></> : <>
                          <NavLink onClick={() => setRead(!read)} exact to="/hospital_nurse" activeClassName="active" className="inactive">
                            <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                          </NavLink>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/patientlist" activeClassName="active" className="inactive">
                            <b>{"PATIENT RECORDS"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavDropdown
                            ref={ref}
                            style={{ zIndex: "1001" }}
                            title={
                              <>
                                <div style={{ display: "flex" }}>
                                  <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                                  {img ? <img
                                    style={{
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    src={img}
                                    alt="Img"
                                  ></img> : <div className="defaultprofile-nav">
                                    <RiUserLine style={{ fontSize: '20px' }} />
                                  </div>}
                                </div>
                              </>
                            }
                            id="basic-nav-dropdown"
                            renderMenuOnMount={true}
                            noCaret

                            onClick={handleOpen}
                            show={show}
                          >
                            {/* <NavDropdown.Item>
                    <Link to="/doctor/profile">Profile</Link>
                  </NavDropdown.Item> */}
                            <button
                              className="btn login-btn logout-btn"
                              onClick={() => navigate("/doctor/profile")}
                            >
                              Profile
                            </button>
                            <button
                              className="btn login-btn logout-btn"
                              onClick={handleLogout1}
                            >
                              Logout
                            </button>
                          </NavDropdown>
                          <div>

                          </div>{" "}
                        </>
                      ) : (login_datas.user_type === "hospital_bdo" || login_datas.user_type === "hospital_pro") ? (
                        <>{projType === "home_care" ? <>
                          <NavLink onClick={() => setRead(!read)} exact to="/hospital_BDO" activeClassName="active" className="inactive">
                            <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/selected-services" activeClassName="active" className="inactive">
                            <b>{"SERVICES"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/service_request" activeClassName="active" className="inactive">
                            <b>{"SERVICE FULFILLMENT"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }} to="/notifications">
                            <div
                              style={{ pointerEvents: "none" }}
                              className="inactive notification"
                              activeClassName="active">
                              <i
                                className="fas fa-bell fa-1x"
                                aria-hidden="true"
                              ></i>
                              <span
                                style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                                className="badge"
                              >
                                {notification}
                              </span> </div>
                            {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                          </NavLink></> : <> <NavLink onClick={() => setRead(!read)} exact to="/hospital_BDO" activeClassName="active" className="inactive">
                            <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/patientlist" activeClassName="active" className="inactive">
                            <b>{"PATIENT RECORDS"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/admissionreport" activeClassName="active" className="inactive">
                            <b>{"REPORT"}</b>
                          </NavLink></>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                          <NavDropdown
                            ref={ref}
                            style={{ zIndex: "1001", marginTop: -10 }}
                            title={
                              <>
                                <div style={{ display: "flex" }}>
                                  <h6 className="user-name">{format(login_datas.name)}</h6>&nbsp;
                                  {img ? <img
                                    style={{
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    src={img}
                                    alt="Img"
                                  ></img> : <div className="defaultprofile-nav">
                                    <RiUserLine style={{ fontSize: '20px' }} />
                                  </div>}
                                </div>
                              </>
                            }
                            id="basic-nav-dropdown"
                            renderMenuOnMount={true}
                            noCaret

                            onClick={handleOpen}
                            show={show}
                          >
                            {/* <NavDropdown.Item>
                    <Link to="/doctor/profile">Profile</Link>
                  </NavDropdown.Item> */}
                            <button
                              className="btn login-btn logout-btn"
                              onClick={() => navigate("/doctor/profile")}
                            >
                              Profile
                            </button>
                            <button
                              className="btn login-btn logout-btn"
                              onClick={handleLogout1}
                            >
                              Logout
                            </button>
                          </NavDropdown>
                          <div>

                          </div>{" "}
                        </>
                      ) : login_datas.user_type === "hospital" ? (
                        <>
                          <NavLink onClick={() => setRead(!read)} exact to="/hospital" activeClassName="active" className="inactive">
                            <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <NavLink onClick={() => setRead(!read)} exact to="/patientlist" activeClassName="active" className="inactive">
                            <b>{"PATIENT RECORD"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {/* <NavLink onClick={() => setRead(!read)} exact to="/visitor_requests" activeClassName="active" className="inactive">
                            <b>{"VISITOR"}</b>
                          </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}


                          <NavDropdown
                            ref={ref}
                            style={{ zIndex: "1001", marginTop: -10 }}
                            title={
                              <>
                                <div style={{ display: "flex" }}>
                                  <h6 style={{ textTransform: "capitalize" }} className="user-name">{login_datas.name}</h6>&nbsp;
                                  {img ? <img
                                    style={{
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    src={img}
                                    alt="Img"
                                  ></img> : <div className="defaultprofile-nav">
                                    <RiUserLine style={{ fontSize: '20px' }} />
                                  </div>}
                                </div>
                              </>
                            }
                            id="basic-nav-dropdown"
                            renderMenuOnMount={true}
                            noCaret

                            onClick={handleOpen}
                            show={show}
                          >
                            {/* <NavDropdown.Item>
                    <Link to="/profile">Profile</Link>
                  </NavDropdown.Item> */}
                            <button
                              className="btn login-btn logout-btn"
                              onClick={() => navigate("/profile")}
                            >
                              Profile
                            </button>
                            <button
                              className="btn login-btn logout-btn"
                              onClick={handleLogout1}
                            >
                              Logout
                            </button>
                          </NavDropdown>
                          <div>

                          </div>{" "}
                        </>
                      )
                        : login_datas.user_type === "staff" ? (
                          <>
                            <NavLink onClick={() => setRead(!read)} exact to="/staff" activeClassName="active" className="inactive">
                              <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* <NavLink onClick={() => setRead(!read)} exact to="/serviceorderlist" activeClassName="active" className="inactive">
                              <b>{"SERVICES"}</b>
                            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            {/* <NavLink exact to="/Labtest" activeClassName="active"className="inactive">
                  <b>{"LABORATARY"}</b>
                </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <NavLink exact to="/Scantest" activeClassName="active"className="inactive">
                  <b>{"SCAN"}</b>
                </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            {/* <NavLink onClick={() => setRead(!read)} exact to="/configuration" activeClassName="active" className="inactive">
                              <b>{"CONFIGURATIONS"}</b>
                            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            {/* <NavLink
                              onClick={() => { setTimeout(() => { setRead(!read) }, 1000) }}
                              to="/notifications"

                            >
                              <div
                                style={{ pointerEvents: "none" }}
                                className="inactive notification"
                                activeClassName="active"
                              >
                                <i
                                  className="fas fa-bell fa-1x"
                                  aria-hidden="true"
                                ></i>
                                <span
                                  style={{ position: "absolute", top: "-6px", right: "-6px", fontSize: 10 }}
                                  className="badge"
                                >
                                  {notification}
                                </span> </div>
                              {"   "} <b>{"NOTIFICATIONS"}{" "}</b>
                            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            <NavDropdown
                              ref={ref}
                              style={{ zIndex: "1001" }}
                              title={
                                <>
                                  <div style={{ display: "flex" }}>
                                    <h6 className="user-name">{login_datas && format(login_datas.name)}</h6>
                                    <i
                                      style={{
                                        marginTop: "3%"
                                      }}
                                      class="fa fa-angle-down"

                                    ></i>
                                  </div>
                                </>
                              }
                              id="basic-nav-dropdown"
                              renderMenuOnMount={true}
                              noCaret

                              onClick={handleOpen}
                              show={show}
                            >

                              <button
                                className="btn login-btn logout-btn"
                                onClick={handleLogout1}
                              >
                                Logout
                              </button>
                            </NavDropdown>
                          </>
                        ) : (
                          <>  <NavDropdown
                            ref={ref}
                            style={{ zIndex: "1001" }}
                            title={
                              <>
                                <div style={{ display: "flex" }}>
                                  <h6 className="user-name">{login_datas && format(login_datas.name)}</h6>
                                  <i
                                    style={{
                                      marginTop: "3%"
                                    }}
                                    class="fa fa-angle-down"

                                  ></i>
                                </div>
                              </>
                            }
                            id="basic-nav-dropdown"
                            renderMenuOnMount={true}
                            noCaret

                            onClick={handleOpen}
                            show={show}
                          >

                            <button
                              className="btn login-btn logout-btn"
                              onClick={handleLogout1}
                            >
                              Logout
                            </button>
                          </NavDropdown>
                          </>
                        )
          ) : (
            <>

              <div className="">
                {/* <NavLink
                  exact
                  to="/visitor_registration"
                  className=""
                  style={{ textDecoration: "none" }}
                >
                  Visitor Registration
                </NavLink>
                &nbsp; &nbsp;
                <NavLink
                  exact
                  to="/volunteer"
                  className=""
                  style={{ textDecoration: "none" }}
                >
                  Join as Volunteer
                </NavLink> */}


                {regcount ===0 &&from!=="reg"&& <>
                  &nbsp; &nbsp;
                  <NavLink
                    exact
                    to="/signup"
                    className=""
                    style={{ textDecoration: "none",color:"white" }}
                  >
                    Join as Service provider
                  </NavLink></> }
              </div>

            </>
          )
          }

        </div>

      </nav>
      {logoutConfirm === true ? (
        <LogoutConfirmPopup
          show={logoutConfirm}
          onHide={() => {
            setLogoutConfirm(false);
          }}
        />
      ) : null}
      {actionshow === true ? (
        <ActionPopup
          show={actionshow}
          onHide={() => {
            setActionShow(false);
          }}
        />
      ) : null}
    </>
  );
}

export default Navbar;
